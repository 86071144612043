import React from "react";
import { Link as RouterLink } from "gatsby";
import {
  connectStateResults,
  Highlight,
  Hits,
  Index,
  Snippet,
} from "react-instantsearch-dom";
import { Box, Typography, Link } from "@mui/material";

const NoResults = connectStateResults(({ searchResults }) => {
  const hitCount = searchResults && searchResults.nbHits;

  return hitCount === 0 ? <Box sx={{ padding: "10px" }}>no results</Box> : null;
});

const PageHit = ({ hit, className }) => {
  const url = `/writing/${hit.slug}/`;
  return (
    <Link
      component={RouterLink}
      to={url}
      style={{ textDecoration: "none" }}
      className={className}
    >
      <Typography
        variant="h6"
        sx={{
          color: "#fff",
          fontSize: 20,
          lineHeight: 1.2,
          marginBottom: 1,
          transition: ".3s ease-in-out",
        }}
      >
        <Highlight attribute="title" hit={hit} tagName="mark" />
      </Typography>

      <Typography sx={{ fontSize: 12 }}>
        <Snippet attribute="excerpt" hit={hit} tagName="mark" />
      </Typography>
    </Link>
  );
};

const HitsInIndex = ({ index }) => {
  return (
    <Index indexName={index.name}>
      <Hits className="Hits" hitComponent={PageHit} />
    </Index>
  );
};

const SearchResult = ({ indices, className, show }) => {
  return (
    <>
      {show && (
        <Box
          className={className}
          sx={{
            overflow: "auto",
            "& ul": {
              borderTop: "1px solid #4B608D",
              listStyle: "none",
              padding: 0,
              margin: 0,
              marginTop: "20px",
            },
            "& li": {
              borderBottom: "1px solid #4B608D",
              padding: "15px 0",
              "&:hover h6": {
                color: "#1CE7C2",
              },
            },
            "& mark": {
              backgroundColor: "transparent",
              color: "inherit",
            },
          }}
        >
          {indices.map((index) => (
            <HitsInIndex index={index} key={index.name} />
          ))}
          <NoResults />
        </Box>
      )}
    </>
  );
};

export default SearchResult;
