import React, { useState, useMemo } from "react";
import Layout from "../components/Layout";
import { Box, Typography } from "@mui/material";
import bricks from "../images/connect/bricks.svg";
import algoliasearch from "algoliasearch/lite";
import { InstantSearch, connectSearchBox } from "react-instantsearch-dom";
import { PageProps } from "gatsby";
import SearchBox from "../components/search/search-box";
import SearchResultPage from "../components/search/search-result2";
const searchIndices = [{ name: `Pages`, title: `Pages` }];

const search = ({ location }: PageProps) => {
  const params = new URLSearchParams(location.search);
  const parameter1 = params.get("s");
  const [query, setQuery] = useState(parameter1 ? parameter1 : "");

  const VirtalSearchBox = connectSearchBox(() => null);

  const searchClient = useMemo(
    () =>
      algoliasearch(
        process.env.GATSBY_ALGOLIA_APP_ID,
        process.env.GATSBY_ALGOLIA_SEARCH_KEY
      ),
    []
  );

  return (
    <Layout>
      <Box component="div" sx={{ position: "relative" }}>
        <Box
          component="img"
          src={bricks}
          sx={{
            position: "absolute",
            left: 0,
            width: "100%",
            top: "50%",
            transform: "translateY(-50%)",
            zIndex: -1,
            pointerEvents: "none",
          }}
        />

        <Box
          component="div"
          sx={{
            maxWidth: 1082,
            mx: "auto",
            mt: { xs: 12, md: 20 },
            mb: 6,
          }}
        >
          <Typography sx={{ color: "#1CE7C2" }} variant="h2">
            Search results
          </Typography>
        </Box>
      </Box>
      <Box
        component="div"
        sx={{ maxWidth: 1082, mx: "auto", mb: 4, display: { md: "flex" } }}
      >
        <Box
          component="div"
          sx={{
            width: { md: "65%" },
            mb: 1.5,
            pr: { md: 1 },
            flex: 1,
          }}
        >
          <InstantSearch
            indexName={searchIndices[0].name}
            searchClient={searchClient}
          >
            <SearchBox defaultRefinement={query} />
          </InstantSearch>

          <InstantSearch
            searchClient={searchClient}
            indexName={searchIndices[0].name}
            onSearchStateChange={({ query }) => setQuery(query)}
          >
            <VirtalSearchBox defaultRefinement={query} />
            <SearchResultPage
              show={query && query.length > 0}
              indices={searchIndices}
            />
          </InstantSearch>
        </Box>
      </Box>
    </Layout>
  );
};

export default search;
